@import 'node_modules/foundation-sites/scss/prototype/sizing';

/**
 * Map containing the proprieties
 * @type Map
 */
$proprieties: (min-height, max-height) !default;

/**
 * Map containing the dimensions
 * @type Map
 */
$dimensions: (
  400: 400px,
  500: 500px,
  600: 600px,
  700: 700px
) !default;

@mixin phs-minmax-height-classes {
  @each $prop in $proprieties {
    @each $lenght, $percentage in $dimensions {
      .#{$prop}-#{$lenght} {
        #{$prop}: $percentage;
      }
    }
  }
}

@mixin phs-width-classes {
  @for $dim from 1 to 11 {
    .w-#{$dim*10} {
      width: $dim * 10% !important;
    }
  }

  @for $vDim from 1 to 11 {
    .h-#{$vDim*10} {
      height: $vDim * 10% !important;
    }
  }

  .hvh-100 {
    height: 100vh;
  }
}
