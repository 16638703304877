@import '../phs-config';

@mixin phs-links {
  a:not([href]) {
    cursor: default;
  }

  a[href]:not([class*='button']) {
    color: map-get($foundation-palette, secondary);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      bottom: rem-calc(-5px);
      left: 50%;
      right: 50%;
      transition: all 0.5s ease-in-out;
      height: rem-calc(2px);
    }

    &:hover::before {
      left: 0;
      right: 0;
    }

    &.no-effect {
      &::before {
        content: none;
      }
    }

    &.is-black {
      color: black !important;

      &::before {
        background: black !important;
      }
    }
  }
}
