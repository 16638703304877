@mixin CouponCodeComponent {
  #coupon-code-input-container {
    @include breakpoint(medium down) {
      display: flex;
    }
    // #input-area {
    //   width: 100%;
    //   @include breakpoint(large up) {
    //     width: rem-calc(298px);
    //   }
    // }
    #button-area {
      display: contents;
      width: rem-calc(100px);
      margin: rem-calc(0px);
    }
    button[name='reset-coupon-code'] {
      border-radius: rem-calc(20px);
      background-color: map-get($foundation-palette, secondary);
      color: $white;
      padding: rem-calc(5px);
      width: rem-calc(20px);
      height: rem-calc(20px);
      margin-left: rem-calc(-28px);
      margin-top: rem-calc(10px);
      margin-right: rem-calc(8px);
      cursor: pointer;
      font-size: rem-calc(12px);
      position: relative;
      display: none;
    }
    input[name='coupon-code'] {
      @include breakpoint(medium down) {
        border-radius: rem-calc(50px) 0 0 rem-calc(50px);
      }
      display: inline-block;
      width: 100%;
      border: none;
      background-color: #ebebeb;
      outline: none;
      border-radius: rem-calc(10px);
      padding: rem-calc(10px);
      margin-top: 0px;
      height: rem-calc(40px);
      font-size: rem-calc(11px);
    }
    .couponCodeTextExits {
      button[name='reset-coupon-code'] {
        display: block;
      }
    }
    .isCurrentCoupon {
      input[name='coupon-code'] {
        @include breakpoint(medium down) {
          border-radius: rem-calc(50px) rem-calc(50px) rem-calc(50px) rem-calc(50px);
        }
        color: map-get($foundation-palette, primary);
        border-color: $interdark-gray;
        pointer-events: none;
      }
      button[name='apply-coupon-code'] {
        display: none;
      }
      button[name='reset-coupon-code'] {
        display: block;
      }
    }

    button[name='apply-coupon-code'] {
      @include breakpoint(medium down) {
        border-radius: 0 rem-calc(50px) rem-calc(50px) 0;
      }
      background-color: map-get($foundation-palette, secondary);
      color: $white;
      padding: rem-calc(10px);
      width: rem-calc(65px);
      height: rem-calc(40px);
      background-color: #5acfc9;
      font-size: rem-calc(13px);
      font-weight: 800;
      border-top-right-radius: rem-calc(10px);
      border-bottom-right-radius: rem-calc(10px);
    }
    input[name='coupon-code']::-webkit-input-placeholder {
      font-size: rem-calc(12px);
      color: $black;
    }
  }
  #coupon-code-input-container.noCouponCode {
    input[name='coupon-code'] {
      @include breakpoint(medium down) {
        border-radius: rem-calc(50px) rem-calc(50px) rem-calc(50px) rem-calc(50px);
      }
    }
    button[name='apply-coupon-code'] {
      display: none;
    }
    #input-area {
      width: 100%;
    }
    #button-area {
      display: none;
    }
  }
  .accepted-coupon {
    margin: auto;
  }
  .denied-coupon {
    margin: auto;
  }
  .denied-coupon img {
    position: relative;
    left: 28px;
  }
  .denied-coupon h3 {
    margin-bottom: 0;
  }
  div#input-area {
    display: flex !important;
    width: 100%;
  }
  .flex-space {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .font-size-quantity {
    font-size: rem-calc(14px);
  }
  .font-size-price {
    font-size: rem-calc(20px);
  }
}
