@mixin CustomInputText {
  .custom-input-text {
    display: flex;

    input {
      width: 100%;
      padding-left: 0 !important;
      border: none;
      border-bottom: rem-calc(1px) solid #dfe0e0;
      border-radius: 0;
      color: $black !important;
    }

    .custom-input-text-current-length {
      font-size: rem-calc(12px);
      color: #d2d5db;
      pointer-events: none;
      height: rem-calc(20px);
      width: rem-calc(40px);
      position: relative;
      top: rem-calc(30px);
      left: rem-calc(-20px);
      margin-top: rem-calc(-20px);
      margin-left: rem-calc(-35px);
      @include breakpoint(small down) {
        left: 0;
        margin-left: rem-calc(-60px);
      }
    }
  }
}
