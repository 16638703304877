$defaultRadius: 20px;

@mixin PaymentList {
  .payment-method-list.all-round {
    display: flex;
    justify-content: center;
    margin-top: rem-calc(20px);
    @include breakpoint(medium down) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    li {
      cursor: pointer;
      border-radius: rem-calc($defaultRadius) rem-calc($defaultRadius) rem-calc($defaultRadius) rem-calc($defaultRadius) !important;
      margin-bottom: rem-calc(10px);
    }
  }
  .payment-method-list {
    button {
      cursor: pointer;
    }
    list-style-type: none;
    margin: auto;
    padding: 0px;
    .pay-method {
      button {
        width: 100%;
        margin: auto;
      }
      display: flex;
      margin-top: -1px;
      border: 1px solid $interdark-gray;
      padding: rem-calc(10px);
      height: rem-calc(60px);
      img {
        height: rem-calc(40px);
      }
    }
    li {
      &:first-child {
        border-radius: rem-calc($defaultRadius) rem-calc($defaultRadius) 0 0;
      }
      &:last-child {
        border-radius: 0 0 rem-calc($defaultRadius) rem-calc($defaultRadius);
      }
    }
    .payment-method-or {
      padding-top: rem-calc(10px);
    }
  }
  li.pay-method.credit-card {
    width: rem-calc(352px);
    height: rem-calc(176px);
    border: none;
    margin: rem-calc(0 10px);
    margin-right: rem-calc(20px);
    padding: rem-calc(0.5px 0 52.2px);
    border-radius: rem-calc(20px);
    box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    @include breakpoint(medium down) {
      margin:rem-calc(20px 0);
    }
  }
  li.pay-method.paypal {
    border: none;
    width: rem-calc(352px);
    height: rem-calc(176px);
    margin: rem-calc(0 10px);
    padding: rem-calc(0.5px 0 52.2px);
    border-radius: rem-calc(20px);
    box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    @include breakpoint(medium down) {
      margin-top: rem-calc(12px);
    }
  }
  li.pay-method.offline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: rem-calc(352px);
    height: rem-calc(177px);
    border-radius: rem-calc(10px);
    box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    border: none;
    @include breakpoint(medium down) {
     left: 0;
     bottom: 0;
    }
  }

  li.pay-method.offline button {
    display: flex;
    font-weight: 800;
    font-size: rem-calc(14px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header-payment {
    width: rem-calc(352px);
    height: rem-calc(33.5px);
    margin: rem-calc(0 0 47px);
    padding: rem-calc(8.5px 127.5px 8px 126.5px);
    border-top-left-radius: rem-calc(20px);
    border-top-right-radius: rem-calc(20px);
    background-color: #f8f8f8;
    font-size: rem-calc(14px);
    font-weight: 700;
    color: $black;
    white-space: nowrap;
  }
  .header-payment span {
    overflow: hidden;
    white-space: nowrap;
  }
  img.height-credit-card {
    height: rem-calc(96px) !important;
  }
}
