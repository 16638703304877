@import '../phs-config';
@import '../utils/utils';

@mixin phs-menu {
  .siteLogo {
    padding: rem-calc(1px);
  }

  .phs-menu-service {
    padding: rem-calc(12px) 0;
    z-index: 99999999999;

    @include breakpoint(large) {
      padding: rem-calc(20px) 0;

      & .medium-auto.tc {
        z-index: 2;
      }
    }
  }
}
