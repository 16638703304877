@mixin SearchAddressesModal {
  .SearchAddressesModalContainer {
    .dialog {
      height: auto !important;
    }
  }
  .SearchAddressesModal {
    input:focus {
      outline: 0;
    }

    .search-location-input {
      display: flex;
      height: rem-calc(40px);
      padding: 0 rem-calc(12px);
      border-radius: rem-calc(40px);
      background-color: #ebebeb;

      input {
        background-color: #ebebeb;
        border: #ebebeb 0 solid;
        width: 100%;
        text-align: center;
        margin-top: 0 !important
      }

      .search-lens {
        margin-left: auto;
        margin-top: rem-calc(10px);
      }
    }

    .powered-by-google {
      max-width: rem-calc(100px) !important;
      margin: rem-calc(4px) auto;
      @include breakpoint(medium down) {
        max-width: rem-calc(60px) !important;
      }
    }

    .active {
      background-color: #fafafa !important;
    }

    .last {
      border-bottom: 0 solid #ebebeb !important;
    }

    .autocomplete-container {
      overflow: hidden;
      -webkit-transform: translate3d(0, 0, 0); // overflow: hidden for safari
      border-radius: 0 0 rem-calc(20px) rem-calc(20px);
      margin: 0 rem-calc(-20px) rem-calc(-20px);
    }

    .autocomplete-dropdown {
      max-height: rem-calc(380px);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .suggestion-item {
      color: #707070;
      padding: rem-calc(24px) rem-calc(4px) rem-calc(24px) rem-calc(12px);
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-content: stretch;
      justify-content: flex-start;
      align-items: center;

      background-color: #ebebeb;
      border-bottom: rem-calc(1px) solid $interdark-gray;
      font-size: rem-calc(16px);
      text-align: left;
      cursor: pointer;

      @include breakpoint(medium down) {
        font-size: rem-calc(14px);
      }

      picture {
        padding-right: rem-calc(12px);
      }

      img {
        height: rem-calc(32px) !important;
        margin-right: rem-calc(10px);
      }
    }


  }
}
