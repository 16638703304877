@mixin ShareCoupon {
  #share-area {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(3, auto);
    @include breakpoint(medium down) {
      grid-template-columns: repeat(3, 33%);
    }
    .share-icon {
      margin: rem-calc(10px);
    }
    .share-icon img {
      height: rem-calc(30px);
      width: auto;
      margin-bottom: rem-calc(5px);
    }
  }
  .share-with-friends-social-buttons {
    width: 100%;
  }
}
