@import '../phs-config';

@mixin phs-loader {
  #loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    background: rgba(255, 255, 255, 1);
  }
}
