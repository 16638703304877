@import '../phs-config';

// Background Colors
$background-colors: (
  lightGray: $light-gray,
  black: $black,
  orange: map-get($foundation-palette, primary)
);

$bkg-colors: (black, lightGray, orange);

$weights: (
  light: 200,
  regular: 400,
  semibold: 500,
  bold: 700
);

@mixin phs-background-utils {
  @each $bkg in $bkg-colors {
    .bkg-#{$bkg} {
      background: map-get($background-colors, $bkg) !important;
    }
  }
}

@mixin phs-colors-utils {
  @each $bkg in $bkg-colors {
    .txt-#{$bkg} {
      color: map-get($background-colors, $bkg) !important;
    }
  }

  .txt-photo-gray {
    color: #999;
  }
}

// Transition mixin
@mixin addTransition($target, $timing, $animation) {
  transition: $target $timing $animation;
}

@mixin phs-overflow-hidden {
  .ovfh {
    overflow: hidden !important;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  }
}

@mixin phs-font-weights {
  @each $weight, $val in $weights {
    .ft-#{$weight} {
      font-weight: $val;
    }
  }
}

@mixin phs-display-flex {
  .d-flex {
    display: flex;
  }
}
