@import '../phs-config';
@import 'node_modules/foundation-sites/scss/components/visibility';

@mixin phs-visbility-classes {
  .hide {
    display: none !important;
  }

  .invisible {
    visibility: hidden !important;
  }

  .hide-between-medium {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      display: none;
    }
  }

  @each $size in $breakpoint-classes {
    @if $size != $-zf-zero-breakpoint {
      .hide-#{$size} {
        @include hide-for($size);
      }

      .show-#{$size} {
        @include show-for($size);
      }
    }

    .hide-#{$size}-only {
      @include hide-for-only($size);
    }

    .show-#{$size}-only {
      @include show-for-only($size);
    }
  }
}
