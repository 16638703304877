@import 'node_modules/foundation-sites/scss/xy-grid/xy-grid';
@import 'node_modules/foundation-sites/scss/xy-grid/cell';
@import 'node_modules/foundation-sites/scss/util/breakpoint';
@import '../phs-config';

@mixin makeGrid {
  .#{$phs-prefix}-container {
    @include breakpoint(xxsmall) {
      @include xy-grid-container($global-width-md);

      @media only screen and (max-width: 540px) and (min-width: $global-width-sm) {
        .grid-x .xxsmall-12 {
          width: 100%;

          .cell {
            margin: auto;
            width: 85%;
          }
        }
      }
    }

    @include breakpoint(large) {
      @include xy-grid-container($global-width-lg);
    }

    @include breakpoint(xlarge) {
      @include xy-grid-container($global-width-xl);
    }

    @include breakpoint(xxlarge) {
      @include xy-grid-container($global-width-xxl);
    }

    &.fluid {
      @include xy-grid-container(100%);
    }

    &.full {
      @include xy-grid-container(100%, 0);

      @include breakpoint(medium) {
        @include xy-grid-container($global-width-md);
      }

      @include breakpoint(large) {
        @include xy-grid-container($global-width-lg);
      }

      @include breakpoint(xlarge) {
        @include xy-grid-container($global-width-xl);
      }

      @include breakpoint(xxlarge) {
        @include xy-grid-container($global-width-xxl);
      }
    }
  }
}
