@mixin ShippingMethod {
  .shipping-method-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    text-align: center;
    @include breakpoint(medium down) {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      text-align: center;
      position: relative;
    }
    .shipping-method-card[data-selected='true'] {
      border: none;
    }
    .shipping-method-card {
      box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      @include breakpoint(medium up) {
        width: rem-calc(480px);
        height: rem-calc(360px);
      }
      @include breakpoint(medium down) {
        margin: rem-calc(3px);
        margin-bottom: rem-calc(5px);
      }
      @include breakpoint(small down) {
        margin: rem-calc(0px);
        width: 95%;
        margin-bottom: rem-calc(20px);
      }
      overflow: hidden;
      margin: rem-calc(20px);
      display: inline-block;
      width: rem-calc(536px);
      height: rem-calc(240px);
      border: none;
      border-radius: rem-calc(20px);
      .image {
        background-position: left center;
        background-color: $interdark-gray;
        height: calc(100% - 85px);
        background-size: cover;
        @include breakpoint(small down) {
          height: calc(100% - 75px);
        }
      }
      .description {
        text-align: center;
        table {
          margin: auto;
          @include breakpoint(small down) {
            margin-top: rem-calc(0);
          }
        }
        padding: rem-calc(10px);
      }
      .icon {
        object-fit: scale-down;
        border-radius: rem-calc(100px);
        padding: rem-calc(0) rem-calc(10px);
        width: rem-calc(56px);
        height: rem-calc(56px);
        position: relative;
        bottom: rem-calc(6px);
        background-color: rgba(235, 93, 11, 0.2);
        @include breakpoint(small down) {
          width: rem-calc(40px);
          height: rem-calc(40px);
        }
      }
    }
  }
  .text-left {
    text-align: left;
  }
  .position-fixed-back {
    position: relative;
    bottom: rem-calc(200px);
    right: rem-calc(500px);
  }
  .v-align {
    vertical-align: baseline;
    font-size: rem-calc(15px);
    @include breakpoint(medium down) {
      margin-top: rem-calc(7px);
      font-size: rem-calc(11px);
    }
  }
  @include breakpoint(medium down) {
    td.text-left.v-align {
      margin-top: rem-calc(7px);
      padding-top: rem-calc(5px);
      padding-bottom: 0;
      padding-left: rem-calc(8px);
    }
  }
  td.pad-right {
    padding: rem-calc(10px);
    position: relative;
    bottom: rem-calc(3px);
    @include breakpoint(medium down) {
       padding: rem-calc(15px) 0;
      }
  }
  .date-formatted {
    position: relative;
    bottom: rem-calc(86px);
    left: rem-calc(19px);
    font-size: rem-calc(14px);
    font-weight: 800;
    @include breakpoint(medium down) {
      bottom: rem-calc(62px);
      left: rem-calc(4px);
    }
    @include breakpoint(small down) {
      bottom: rem-calc(46px);
      left: rem-calc(72px);
      font-size: rem-calc(12px);
    }
  }
  .date-formatted-pickup {
    position: relative;
    bottom: rem-calc(86px);
    left: rem-calc(40px);
    font-size: rem-calc(14px);
    font-weight: 800;
    @include breakpoint(medium down) {
      bottom: rem-calc(62px);
      left: rem-calc(22px);
    }
    @include breakpoint(small down) {
      bottom: rem-calc(46px);
      left: rem-calc(89px);
      font-size: rem-calc(12px);
    }
  }
  .card-title-shipping{
    
    font-size: rem-calc(15px);
    color: $black;
    @include breakpoint(small down) {
      font-size: rem-calc(12px);
    }
  }
  .total-price-shipping-card{
    font-size: rem-calc(15px);
    font-weight: bold;
    color: $black;
    @include breakpoint(small down) {
      font-size: rem-calc(12px);
    }
  }
}
