@mixin OrderSuccess {
  #order-success {
    display: flex;
    flex-direction: column;
    background-image: url(../../images/jpg/404_pc_photo.jpg);
    position: relative;

    @include breakpoint(medium down) {
      grid-template-columns: repeat(1, 100%);
    }

    .success_logo {
      max-height: rem-calc(135px);
      @include breakpoint(medium down) {
        max-height: rem-calc(135px);
      }
    }
    .position-logo {
      position: relative;
      bottom: 0;
    }
    .position-logo-badge {
      position: relative;
      bottom: 0;
      margin-right: 5px;
    }
    #right-column {
      h2.top-title {
        font-size: rem-calc(16px);
        font-weight: 500;
        margin-top: rem-calc(10px);
        margin-bottom: rem-calc(10px);
        position: relative;
        top: rem-calc(18px);
        @include breakpoint(medium down) {
          top: rem-calc(18px);
        }
      }
      h1 {
        margin-top: rem-calc(10px);
        margin-bottom: rem-calc(10px);
      }
      display: grid;
      z-index: 5;
      div.sub-title {
        font-size: rem-calc(14px);
        color: $black;
        margin: auto;
        width: rem-calc(248px);
        position: relative;
        top: -20px;
      }
    }
    button {
      margin-bottom: rem-calc(10px) !important;
      width: rem-calc(295px);
      overflow: hidden;
      white-space: nowrap;
      font-size: rem-calc(12px);
    }

    .button-container {
      padding-top: rem-calc(10px);
      padding-bottom: rem-calc(10px);

      .hollow {
        background-color: $white;
      }
      @include breakpoint(medium down) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
      }
    }
  }

  button.phs-button.secondary.font-formatte,
  button.phs-button.secondary.hollow.font-formatted-nobg {
    font-size: rem-calc(16px);
  }

  .sub-title.sub-title-format {
    font-weight: 500 !important;
  }
  .title-format {
    font-size: rem-calc(20px);
  }
}
.margin-bottom-unicorn {
  width: auto;
  position: absolute;
  /* margin-bottom: 50%; */
  top: 0;
  left: 50%;
  margin-left: rem-calc(-450px);
  @include breakpoint(medium down) {
    left: 0;
    margin-left: 0;
  }
}

.bg_blue_bottom_background {
  background-color: #e3fffe;
  border-radius: 50% / 40px 40px 0px 0px;
  left: 0;
  z-index: 1;
  @include breakpoint(medium down) {
    bottom: 0;
    width: calc(100% + 30px);
    margin-left: rem-calc(-15px);
    margin-bottom: rem-calc(-250px);
  }
  @include breakpoint(small down) {
    height: 60vh;
  }
}
.margin-bottom-page-correction {
  pointer-events: none;
  margin-bottom: rem-calc(-21px);
}
