// Config file for PhotoSi instead of Foundation

@import 'node_modules/foundation-sites/scss/util/util';
@import './utils/urls';
/**
 * Prefix for our classes
 */
$phs-prefix: 'phs';

/**
 * Font size attribute applied to `<html>` and `<body>`
 * Foundation use 100% by default so the value is inherited from the user's browser settings
 * @type Number
 */
$global-font-size: 100%;

/**
 * Global width of the site.
 * Used by the Grid to determine row width.
 * @type Number
 */
$global-width-sm: 330px;
$global-width-md: calc(100% - 30px);
$global-width-lg: 1140px;
$global-width-xl: 1280px;
$global-width-xxl: 1440px;

$global-padding-sm: 15px;
$global-padding-md: 80px;
$global-padding-lg: 140px;

$breakpoints: (
  xxsmall: 0px,
  xsmall: 360px,
  small: 540px,
  medium: 768px,
  // large: 1366px,
  large: 1280px,
  xlarge: 1440px,
  xxlarge: 1920px
);
$print-breakpoint: large;
$breakpoint-classes: (xxsmall xsmall small medium large xlarge xxlarge);
// 56. Xy Grid
// -----------

$grid-margin-gutters: (
  xxsmall: 0px,
  xsmall: 10px,
  small: 10px,
  medium: 16px,
  large: 16px,
  xlarge: 16px,
  xxlarge: 16px
);

/**
 * Default line height for all type.
 * `$global-lineheight` is 24px while `$global-font-size` is 16px.
 * @type Number
 */
$global-lineheight: 1.2;

/**
 * Default line height for paragraph
 * @type Number
 */
$paragraph-lineheight: 1.2;

/**
 * Colors used around the framework.
 * There must always be a color called `primary`.
 * @type Map
 */
$foundation-palette: (
  primary: #eb5d0b,
  secondary: #5acfc9,
  success: #eee,
  warning: #ff3b30
);

$phs-colors: (primary, secondary, success, warning);

/**
 * Color used for light gray UI items.
 * @type Color
 */
$light-gray: #f8f8f8;

/**
 * Color used for medium gray UI items.
 * @type Color
 */
$medium-gray: #cccccc;

/**
 * Color used for interdark gray UI items.
 * @type Color
 */
$interdark-gray: #d2d5db;

/**
*   Color user for interline between greenlight/checkbox
* @type Color
*/
$interlight-green: #5acfc9;

/**
 * Color used for dark gray UI items.
 * @type Color
 */
$dark-gray: #666666;

/**
 * Color used for black UI items.
 * @type Color
 */
$black: #000000;

/**
 * Color used for white UI items.
 * @type Color
 */
$white: #ffffff;

/**
 * Background color of the body.
 * @type Color
 */
$body-background: $white;

/**
 * Text color of the body.
 * @type Color
 */
$body-font-color: $black;

/**
 * Font Stack of the body.
 * @type Color
 */
$body-font-family: 'Lato', sans-serif;
$lubalin-font: 'LubalinDemi';

/**
 * Set to `true` to enable antialiased type,
 * using the `-webkit-font-smoothing` and `-moz-osx-font-smoothing` CSS properties.
 * @type Boolean
 */
$body-antialiased: true;

/**
 * Global value used for margin on components.
 * @type Number
 */
$global-margin: 1rem;

/**
 * Global value used for padding on components.
 * @type Number
 */
$global-padding: 1rem;

/**
 * Global font weight used for normal type.
 * @type Keyword | Number
 */
$global-weight-normal: normal;

/**
 * Global font weight used for bold type.
 * @type Keyword | Number
 */
$global-weight-bold: bold;

/**
 * Global value used for all elements that have a border radius.
 * @type Number
 */
$global-radius: 0;
