@mixin GenericForm {
  form {
    width: 100%;
    max-width: rem-calc(520px);
    margin: auto;
    .button-area {
      @include breakpoint(medium down) {
        display: flex;
        flex-flow: column;
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
        background-color: $light-gray;
        padding-top: rem-calc(20px);
        padding-left: rem-calc(20px);
        padding-right: rem-calc(20px);
        button {
          margin: auto !important;
          margin-bottom: rem-calc(15px) !important;
          width: 100% !important;
        }
        button[name='submit'] {
          order: 0;
        }
        label {
          order: 1;
        }
      }
      button {
        margin-left: rem-calc(5px) !important;
        margin-right: rem-calc(5px) !important;
      }
      margin-top: rem-calc(10px);
      margin-bottom: rem-calc(10px);
    }

    input::placeholder {
      color: #333;
      opacity: 0.5;
    }
    .width-input-left-country .width-input-left {
      // color: #333;
      // opacity: 0.5;
    }
    textarea {
      height: rem-calc(170px);
    }
    .error {
      color: #999999;
      border-color: red !important;
    }
    .valid {
      border-color: map-get($foundation-palette, primary);
    }
    button[disabled] {
      pointer-events: none;
      opacity: 0.65;
      border-color: $interdark-gray !important;
    }
    input,
    select {
      display: flex;
      margin-right: rem-calc(15px);
      margin-bottom: rem-calc(10px);
      margin-top: rem-calc(0);
      width: 100%;
      padding: rem-calc(10px);
      height: rem-calc(40px);
      font-size: rem-calc(14px);
      line-height: 1.36;
      text-align: left;
      border-radius: 0;
      // color: #333;
      // opacity: 0.5;
    }
    .width-input-right-address{
     color: red;
    }
    .width-input,
    .width-input-normal {
      width: rem-calc(260px);
      padding-left: 0;
      border: none;
      border-bottom: rem-calc(1px) solid #dfe0e0;
      border-radius: 0;
     color: $black;
      opacity: 0.9;
    }
    .width-input-right,
    .width-input-left,
    .width-input-right-address,
    .width-input-left-country {
      width: rem-calc(352px);
      padding-left: 0;
      border: none;
      border-bottom: rem-calc(1px) solid #dfe0e0;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      color: $black;
      opacity: 0.9;
      @include breakpoint(medium down) {
        width: rem-calc(260px);
        margin-left: 0;
        margin-right: rem-calc(15px);
      }
    }
    .width-input-right {
      color: $black;
      opacity: 0.9;
    }
    .width-input-right-address,
    .width-input-left-country {
      width: rem-calc(168px);
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      @include breakpoint(medium down) {
        width: rem-calc(260px);
      }
    }
    option {
      color: $black;
      opacity: 0.9;
    }

    .button-width {
      width: rem-calc(211px);
    }
    .label-form,
    .label-form-provincia,
    .info-tel,
    .request-form-input {
      font-size: rem-calc(12px);
      font-weight: 500;
      line-height: 1.08;
      width: 16.25rem;
      text-align: left;
      margin: auto;
      color: #333;
      opacity: 0.5;
    }

    .label-form-provincia {
      @include breakpoint(medium down) {
        padding-left: 0;
      }
    }
    .info-tel {
      margin-left: rem-calc(0px);
      font-size: rem-calc(10px);
      display: block;
    color: #333;
      opacity: 0.5;
      @include breakpoint(medium down) {
        margin: auto;
      }
    }
    .request-form-input {
      font-size: rem-calc(10px);
     color: #333;
      opacity: 0.5;
    }
    .error-container ul {
      list-style-type: none;
      @include breakpoint(medium down) {
        padding-left: 0;
      }
    }
    .error-container ul li {
      border: solid rem-calc(1px) #ff3b3b;
      background-color: #fdcfcf;
      font-size: rem-calc(12px);
      line-height: 1.33;
      color: #ff3b3b;
      margin-bottom: rem-calc(5px);
    }
    .align-label {
      text-align: left;
      @include breakpoint(medium down) {
        text-align: center;
      }
      @include breakpoint(small down) {
        margin: auto;
        width: 16.25rem;
        text-align: left;
      }
    }
  }
}
