@mixin EditProductQuantity {
  .editProductQuantity {
    text-align: center;
    padding: rem-calc(0px) !important;
    max-width: rem-calc(80px);
    .circle {
      background-color: $white;
      color: $black;
      border-radius: 50%;
      border-radius: rem-calc(50%);
      height: rem-calc(20px);
      width: rem-calc(20px);
      text-align: center;
      line-height: 0;
      margin: rem-calc(0px);
      padding: rem-calc(0px);
      cursor: pointer;
      background-color: white;
      border: rem-calc(1px) black solid;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      margin: auto;
      width: rem-calc(40px);
      text-align: center;
      padding: rem-calc(0px);
      border: 0px none transparent;
      -moz-appearance: textfield;
      @include breakpoint(medium down) {
        margin-top: rem-calc(2px);
      }
    }
  }
  .display-none-mobile {
    @include breakpoint(medium down) {
      display: none;
    }
  }
}
