@mixin PageHeader {
  @include breakpoint(medium up) {
    #back-button,
    #title {
      display: none;
    }
  }
  @include breakpoint(medium down) {
    .page-header {
      flex-direction: column;
      flex-grow: 3;
      justify-content: space-evenly;
      #back-button {
        width: 25%;
        color: map-get($foundation-palette, secondary);
        font-size: rem-calc(13px);
        .back-button-content {
          display: none;
          margin: rem-calc(5px);
          img {
            width: rem-calc(8px);
            height: rem-calc(13.7px);
            margin-right: rem-calc(5px);
          }
        }
      }
      #title {
        font-size: rem-calc(17px);
        text-align: center;
        font-weight: bold;
        color: $black;
        width: 50%;
      }
      #logo {
        width: 25%;
        text-align: right;
        img {
          height: rem-calc(15px);
        }
      }
    }
  }
}
