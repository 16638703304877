@mixin ErrorGeneric {
  background: transparent;
  .error-image {
    @include breakpoint(medium) {
      height: rem-calc(300px) !important;
    }
    @include breakpoint(large up) {
      height: rem-calc(400px) !important;
    }
  }
}
