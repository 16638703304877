@mixin Error404 {
  .grid-404 {
    .image {
      max-width: rem-calc(400px);
    }
    .text {
      max-width: rem-calc(400px);
    }
  }
}
