@import '../phs-config';
@import 'node_modules/foundation-sites/scss/typography/base';

$aligns: (
  left: l,
  center: c,
  right: r,
  justify: j
);

$transformation: (
  upper: uppercase,
  lower: lowercase,
  capit: capitalize
);

$header-styles: (
  xsmall: (
    'h1': (
      'font-size': 22
    ),
    'h2': (
      'font-size': 20
    ),
    'h3': (
      'font-size': 16
    ),
    'h4': (
      'font-size': 15
    ),
    'h5': (
      'font-size': 14
    )
  ),
  medium: (
    'h1': (
      'font-size': 27
    ),
    'h2': (
      'font-size': 23
    ),
    'h3': (
      'font-size': 18
    ),
    'h4': (
      'font-size': 18
    ),
    'h5': (
      'font-size': 17
    )
  ),
  large: (
    'h1': (
      'font-size': 30
    ),
    'h2': (
      'font-size': 26
    ),
    'h3': (
      'font-size': 21
    ),
    'h4': (
      'font-size': 19
    ),
    'h5': (
      'font-size': 17
    )
  ),
  xxlarge: (
    'h1': (
      'font-size': 40
    ),
    'h2': (
      'font-size': 30
    ),
    'h3': (
      'font-size': 26
    ),
    'h4': (
      'font-size': 24
    ),
    'h5': (
      'font-size': 22
    )
  )
);

$small-font-size: 75%;
$header-small-font-color: $black;

$enable-code-inline: false;

@mixin phs-text-alignment {
  //@include foundation-typography-base;

  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      @each $align in (left, right, center, justify) {
        @if $size != $-zf-zero-breakpoint {
          .#{$size}-t#{map-get($aligns, $align)} {
            text-align: $align;
          }
        } @else {
          .t#{map-get($aligns, $align)} {
            text-align: $align;
          }
        }
      }
    }
  }
}

@mixin phs-text-transformation {
  @each $transform, $val in $transformation {
    .t#{$transform} {
      text-transform: $val;
    }
  }

  .txtCapitalize {
    text-transform: capitalize;
  }

  // Headings
  @each $size, $headers in $header-styles {
    @include breakpoint($size) {
      @each $header, $header-defs in $headers {
        $font-size-temp: 1rem;
        #{$header},
        .#{$header} {
          @if map-has-key($header-defs, font-size) {
            $font-size-temp: rem-calc(map-get($header-defs, font-size));
            font-size: $font-size-temp;
          } @else if map-has-key($header-defs, fs) {
            $font-size-temp: rem-calc(map-get($header-defs, fs));
            font-size: $font-size-temp;
          } @else if $size == $-zf-zero-breakpoint {
            font-size: $font-size-temp;
          }
          @if map-has-key($header-defs, line-height) {
            line-height: unitless-calc(map-get($header-defs, line-height), $font-size-temp);
          } @else if map-has-key($header-defs, lh) {
            line-height: unitless-calc(map-get($header-defs, lh), $font-size-temp);
          } @else if $size == $-zf-zero-breakpoint {
            line-height: unitless-calc($header-lineheight, $font-size-temp);
          }

          @if map-has-key($header-defs, margin-top) {
            margin-top: rem-calc(map-get($header-defs, margin-top));
          } @else if map-has-key($header-defs, mt) {
            margin-top: rem-calc(map-get($header-defs, mt));
          } @else if $size == $-zf-zero-breakpoint {
            margin-top: 0;
          }
          @if map-has-key($header-defs, margin-bottom) {
            margin-bottom: rem-calc(map-get($header-defs, margin-bottom));
          } @else if map-has-key($header-defs, mb) {
            margin-bottom: rem-calc(map-get($header-defs, mb));
          } @else if $size == $-zf-zero-breakpoint {
            margin-bottom: rem-calc($header-margin-bottom);
          }
        }
      }
    }
  }
}

@mixin phs-hr {
  hr {
    &.w-100 {
      max-width: 100%;
    }
  }
}
