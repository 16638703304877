@import '../phs-config';
@import 'node_modules/foundation-sites/scss/prototype/spacing';

$phs-mar-pad: (
  margin: m,
  padding: p
);

$phs-directions: (
  top: t,
  right: r,
  bottom: b,
  left: l,
  horizontal: x,
  vertical: y
);

@mixin phs-spacing {
  @if ($breakpoint-classes) {
    @each $size in $breakpoint-classes {
      @include breakpoint($size) {
        @for $spacer from 0 through 10 {
          .#{$size} {
            @each $prop in (margin, padding) {
              &-#{map-get($phs-mar-pad, $prop)}-#{$spacer} {
                @if ($prop == margin) {
                  margin: $spacer * $global-margin !important;
                } @else if ($prop == padding) {
                  padding: $spacer * $global-padding !important;
                }
              }

              @each $dir in (top, right, bottom, left, horizontal, vertical) {
                &-#{map-get($phs-mar-pad, $prop)}#{map-get($phs-directions, $dir)}-#{$spacer} {
                  @if ($prop == margin) {
                    @include margin-direction($dir, $spacer);
                  } @else if ($prop == padding) {
                    @include padding-direction($dir, $spacer);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
