@import '../phs-config';
@import '../utils/utils';
@import 'node_modules/foundation-sites/scss/components/button';

$button_360: (
  padding: 13px,
  border-radius: 10px,
  font-size: 18px
);

$button_1366: (
  padding: 13px,
  border-radius: 10px,
  font-size: 16px
);

$button_1440: (
  padding: 15px,
  border-radius: 10px,
  font-size: 16px
);

$button_1920: (
  padding: 15px,
  border-radius: 10px,
  font-size: 21px
);

@mixin buttons() {
  .phs-button,
  input[type='submit'].hs-button {
    @include button-base;
    @include addTransition(transform, 0.5s, ease-in-out);
    margin: map-get($button_360, margin);
    padding: rem-calc(map-get($button_360, padding));
    border-radius: rem-calc(map-get($button_360, border-radius));
    font-size: rem-calc(map-get($button_360, font-size));
    text-decoration: none;
    font-weight: bold;
    outline: none;

    @each $color in $phs-colors {
      &.#{$color} {
        @include button-style(map-get($foundation-palette, $color), map-get($foundation-palette, $color));

        &.disabled,
        &[disabled] {
          @include button-disabled($interdark-gray);
          pointer-events: none;
          opacity: 1 !important;
        }

        &.hollow {
          position: relative;
          background: transparent;
          color: map-get($foundation-palette, $color);
          border: 1px solid map-get($foundation-palette, $color);
          outline: none;

          &:hover {
            transform: scale(1.05);
          }

          &.disabled,
          &[disabled] {
            @include button-disabled($interdark-gray);
            pointer-events: none;
            opacity: 1 !important;
          }
        }
      }
    }

    &:hover {
      transform: scale(1.1);
    }

    @include breakpoint(medium) {
      width: 35%;
    }

    @include breakpoint(large) {
      margin: map-get($button_1366, margin) !important;
      padding: rem-calc(map-get($button_1366, padding)) !important;
      border-radius: rem-calc(map-get($button_1366, border-radius)) !important;
      //font-size: rem-calc(map-get($button_1366, font-size)) !important;
      width: 25%;
    }

    @include breakpoint(xlarge) {
      margin: map-get($button_1440, margin) !important;
      padding: rem-calc(map-get($button_1440, padding)) !important;
      border-radius: rem-calc(map-get($button_1440, border-radius)) !important;
      //font-size: rem-calc(map-get($button_1440, font-size)) !important;
    }

    @include breakpoint(xxlarge) {
      margin: map-get($button_1920, margin) !important;
      padding: rem-calc(map-get($button_1920, padding)) !important;
      border-radius: rem-calc(map-get($button_1920, border-radius)) !important;
      //font-size: rem-calc(map-get($button_1920, font-size)) !important;
    }
  }
  button,
  input[type='button'] {
    cursor: pointer;
  }
}

@mixin phs-buttons {
  @include buttons();
}
