@import '../phs-config';
@import 'node_modules/foundation-sites/scss/util/flex';

$phs-justify: (
  'left': flex-start,
  'right': flex-end,
  'center': center,
  'justify': space-between,
  'spaced': space-around
);

$phs-align: (
  'top': flex-start,
  'bottom': flex-end,
  'middle': center,
  'stretch': stretch
);

@mixin phs-flex-classes {
  @each $break in $breakpoint-classes {
    @include breakpoint($break) {
      @if $break != $-zf-zero-breakpoint {
        // horizontal
        @each $hdir, $prop in $phs-justify {
          .#{$phs-prefix}-#{$break}-alg-#{$hdir} {
            @include flex-align($x: $hdir);
          }
        }

        // vertical
        @each $vdir, $prop in $phs-align {
          .#{$phs-prefix}-#{$break}-alg-#{$vdir} {
            @include flex-align($y: $vdir);
          }

          .#{$phs-prefix}-#{$break}-alg-self-#{$vdir} {
            @include flex-align-self($y: $vdir);
          }
        }

        .#{$phs-prefix}-#{$break}-alg-center-middle {
          @include flex-align($x: center, $y: middle);
          align-content: center;
        }

        @for $i from 1 through 20 {
          .#{$break}-order-#{$i} {
            @include flex-order($i);
          }
        }

        .#{$phs-prefix}-#{$break}-jc-space-evenly {
          justify-content: space-evenly;
        }
      } @else {
        @each $hdir, $prop in $phs-justify {
          .#{$phs-prefix}-alg-#{$hdir} {
            @include flex-align($x: $hdir);
          }
        }

        @each $vdir, $prop in $phs-align {
          .#{$phs-prefix}-alg-#{$vdir} {
            @include flex-align($y: $vdir);
          }

          .#{$phs-prefix}-alg-self-#{$vdir} {
            @include flex-align-self($y: $vdir);
          }
        }

        .#{$phs-prefix}-alg-center-middle {
          @include flex-align($x: center, $y: middle);
          align-content: center;
        }

        @for $i from 1 through 20 {
          .order-#{$i} {
            @include flex-order($i);
          }
        }
      }
    }
  }
}
