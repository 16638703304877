@mixin CustomSelect {
  .custom-select {
    display: flex;
    .select-icon {
      pointer-events: none;
      height: rem-calc(20px);
      width: rem-calc(20px);
      position: relative;
      top: rem-calc(30px);
      left: rem-calc(-20px);
      margin-top: rem-calc(-20px);
      margin-left: rem-calc(-20px);
      @include breakpoint(small down) {
        left: 0;
      }
    }
  }
}
