@import '../phs-config';
@import 'node_modules/foundation-sites/scss/prototype/bordered';

$borders-map: (
  top: 't',
  right: 'r',
  bottom: 'b',
  left: 'l'
);

$borders: (top right bottom left);

$prototype-border-color: $interdark-gray;

@mixin phs-border-utils {
  .bordered {
    @include bordered;
  }

  @each $size in $breakpoint-classes {
    @include breakpoint($size) {
      @for $spacer from 0 through 5 {
        @each $border in $borders {
          .#{$size}-b#{map-get($borders-map, $border)}-#{$spacer} {
            border-#{$border}: #{$spacer}px solid $dark-gray;
          }
        }
      }
    }
  }
}
