$defaultRadius: 20px;

@mixin ShippingMethodShortcut {
  .payment-method-or-container {
    margin: auto;
    width: 100%;
    .payment-method-or {
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem-calc(250px);
      margin: auto;
      .line {
        margin: auto;
        width: rem-calc(32px);
        height: rem-calc(1px);
        border: 0;
        border-top: rem-calc(1px) solid #707070;
      }
      .translate {
        margin: auto;
      }
    }
    // .line {
    //   width: rem-calc(32px);
    //   height: 0;
    //   border: rem-calc(1px) solid #707070;
    //   margin: rem-calc(3px);
    //   display: inline-block;
    // }
    // .translate {
    //   display: inline-block;
    //   padding: 0 rem-calc(10px);
    // }
  }

  .shipping-method-shortcut-container {
    display: flex;
    justify-content: center;
    margin: rem-calc(20px) 0;
    @include breakpoint(medium down) {
      margin: 1rem 1rem 0 1rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .shipping-method-shortcut-card {
      box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
      overflow: hidden;
      height: rem-calc(172px);
      width: rem-calc(728px);
      border: none;
      border-radius: rem-calc(20px);
      background: $white;
      display: flex;
      flex-direction: row;
      @include breakpoint(small down) {
        margin: rem-calc(0px);
        height: rem-calc(344px);
        width: 100%;
        flex-direction: column;
        margin-bottom: rem-calc(20px);
      }
      .description {
        width: 50%;
        height: 100%;
        padding: rem-calc(20px);
        @include breakpoint(medium down) {
          width: 100%;
        }
        .title {
          font-size: rem-calc(16px);
        }
        .body {
          height: rem-calc(16px);
          padding-top: rem-calc(10px);
          font-size: rem-calc(15px);
          margin-bottom: rem-calc(16px);
        }
        button.go-to-map {
          margin: rem-calc(20px);
          background: $white;
          font-size: rem-calc(16px);
          // font-weight: normal;
        }
      }
      .image {
        width: 50%;
        height: 100%;
        background-position: left center;
        background-color: $interdark-gray;
        background-size: cover;
        @include breakpoint(medium down) {
          width: 100%;
        }
      }
    }
  }
}
